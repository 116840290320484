var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品列表",
            visible: _vm.visible,
            width: "896px",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _vm.visible
            ? _c("good-list", {
                attrs: {
                  handleNum: _vm.handleNum,
                  checked: _vm.checked,
                  activityId: _vm.activityId,
                },
                on: { getStoreItem: _vm.getStoreItem },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }